.intro-container {
}

.intro {
    background-color: #ffe4ce;
    
}

.mt-20{
    margin-top:20vh;
}

.dm-sans-500 {
    font-family: "DM Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }

.f-xl{
    font-size: xxx-large;
}

@media (max-width: 1199px) {
    .parallax-intro{
        transform:translateY(130px);
    }
}

.rounded-50{
    border-radius:50px;
}
