.dm-sans-500 {
    font-family: "DM Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }

.f-xl{
    font-size: xxx-large;
}

.high{
    height:20vh;
}


.font-std{
    font-size: 15pt;
    white-space: pre-wrap
}