.graphic-box {
    background-color: #ffe4ce;
    overflow:hidden;
}

.mt-20{
    margin-top:20vh;
}

.mt-10{
    margin-top:10vh;
}